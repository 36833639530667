<template>
  <div class="common-footer">
    © 2022-2024 Bchannel Inc. All rights reserved.
    <!-- <i></i> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.common-footer {
  position: relative;
  height: 80px;
  position: relative;
  z-index: 99;
  font-size: 28px;
  color: #fff;
  font-family: PingFangSC, PingFang SC;
  line-height: 80px;
  background: #1a1a1a;
}
</style>
