<template>
  <div class="common-footer">
    © 2022-2024 Bchannel Inc. All rights reserved.
    <!-- <i></i> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.common-footer {
  position: relative;
  height: 70px;
  font-size: 14px;
  color: #fff;
  font-family: PingFangSC, PingFang SC;
  line-height: 70px;
  background: #1a1a1a;
  z-index: 999;
  // > i {
  //   position: absolute;
  //   width: 147px;
  //   height: 170px;
  //   right: -20px;
  //   bottom: -40px;
  //   border-radius: 50%;
  //   background: linear-gradient(
  //     162deg,
  //     #000dc3 0%,
  //     #000dc3 20%,
  //     #b758d9 66%,
  //     #ef9961 100%
  //   );
  //   filter: blur(33px);
  //   z-index: 1;
  // }
}
</style>
